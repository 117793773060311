import React, { Component } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import axios from "axios";
import { hhmmssToSec } from '../utilities/time';
import { getCurrentMarkerID } from '../redux/selectors';
import { queryLinks } from '../graphql/links';
import Link from './Link';
import styles from './Links.module.scss';
import { defaultLinks } from '../test-data/defaultLinks';
import { defaultMarkerData } from '../test-data/defaultMarkerData';

interface Props {
  currentMarkerID: number;
  markers?: Marker[];
  rangeMax: number;
}

interface State {
  linkMarkers: LinkMarker[];
  links: object[];
  styleName: string;
}

interface Image {
  sys: {
    id: string;
  }
  url: string;
}

interface CollectionLink {
  sys: {
    id: string;
  }
}

interface Marker {
  imagesCollection: {
    items: Image[]
  }
  note: string
  sys: {
    id: string
  }
  time: string
}

interface LinkMarker {
  sys: {
    id: string
  },
  time: string
  linksCollection: {
    "items": CollectionLink[]
  }
}

const getInitialState = (props: Props):State => {
  return {
    links: [],
    styleName: '',
    linkMarkers: []
  };
};

class Links extends Component<Props, State> {
  wrapperRef = React.createRef<HTMLDivElement>();
  state = getInitialState(this.props);
  componentDidMount() {
    // this.loadContentfulData();
    this.loadLocalData();
  }
  getImg(theID: string, markerData: Marker[]): string {
    return defaultMarkerData[0].imagesCollection.items[0].url;
  }
  getLeftPos(markerTime: string): string {
    if (typeof this.props.rangeMax !== 'undefined') {
      return (hhmmssToSec(markerTime) / this.props.rangeMax * 100) + '%';
    } else {
      return '';
    }
  }
  getLinks() {
    let currentID: number,
        links: CollectionLink[];
    if ((this.props.currentMarkerID) &&
        (this.state.linkMarkers)) {
      currentID = this.props.currentMarkerID;
      links = this.state.linkMarkers[currentID].linksCollection.items;
    } else {
      links = defaultLinks[0].linksCollection.items;
    }
    return [...links];
  }
  getMarker(theID: string, markerData: Marker[]): Marker {
    let marker: Marker | undefined;
    for (let i = 0; i < markerData.length; i++) {
      if (markerData[i].sys.id === theID) {
        marker = markerData[i];
      }
    }
    if (marker) {
      return marker;
    } else {
      return defaultMarkerData[0];
    }
  }
  getNote(markerID: string): string {
    let match: Marker | undefined;
    if (typeof this.props.markers !== 'undefined') {
      match = _.find(this.props.markers, (m: Marker, i: number) => {
        return m.sys.id === markerID;
      });
      return (typeof match !== 'undefined') ? match.note : '';
    } else {
      return '';
    }
  }
  getTime(markerID: string): string {
    let match: Marker | undefined;
    if (typeof this.props.markers !== 'undefined') {
      match = _.find(this.props.markers, (m: Marker, i: number) => {
        return m.sys.id === markerID;
      });
      return (typeof match !== 'undefined') ? match.time : '';
    } else {
      return '';
    }
  }
  getTopPos(theID: number): string {
    if (typeof this.props.markers !== 'undefined') {
      return ((theID / this.props.markers.length) * 100) + '%';
    } else {
      return '';
    }
  }
  loadContentfulData() {
    let {
          REACT_APP_CTFL_ACCESS_TOKEN,
          REACT_APP_CTFL_SPACE_ID
        } = process.env,
        queryURI,
        query = queryLinks;
    queryURI = `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_CTFL_SPACE_ID}`;
    fetch(queryURI,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${REACT_APP_CTFL_ACCESS_TOKEN}`
        },
        body: JSON.stringify({ query })
      })
      .then(response => response.json())
      .then(d => this.prepareData('contentful', d.data))
      .catch((error) => {
        console.log(error);
      });
  }
  loadLocalData() {
    axios
      .get('./data/links.json')
      .then(d => {
        this.prepareData('local', d.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
  prepareData(type: string, d: any) {
    let data = d.data,
        markerLinks = data.markerLinks.items;
    markerLinks = markerLinks.sort(
      (a: Marker, b: Marker) => (hhmmssToSec(a.time) > hhmmssToSec(b.time)) ? 1 : -1
    );
    // console.log(markerLinks);
    this.setState({
      linkMarkers: [...markerLinks]
    });
  }
  render(defMarDat: Marker[] = defaultMarkerData) {
    let linksArray: CollectionLink[],
        linksNodes: any,
        markerData: Marker[];
    if (this.props.markers) {
      markerData = this.props.markers;
    } else {
      markerData = defMarDat;
    }
    if ((this.state.linkMarkers.length > 0) && 
        (this.props.currentMarkerID > -1)) {
      linksArray = this.getLinks();
      linksNodes = linksArray.map((l: CollectionLink, i: number) => {
        let isLast = (i === (linksArray.length - 1)) ? true : false,
            props: any = {
              key: i,
              isLast: isLast,
              id: i,
              img: this.getImg(l.sys.id, markerData),
              note: this.getNote(l.sys.id),
              parent: this.wrapperRef.current,
              leftPos: this.getLeftPos(this.getTime(l.sys.id)),
              time: this.getTime(l.sys.id),
              topPos: this.getTopPos(i)
            };
        return <Link {...props} />;
      });
    }
    return (
      <div ref={this.wrapperRef} className={styles.Links}>
        {linksNodes}
      </div>
    )
  }
}

const mapStateToProps = (state: State) => {
  const currentMarkerID = getCurrentMarkerID(state);
  return { currentMarkerID };
};

export default connect(
  mapStateToProps,
  null
)(Links);
