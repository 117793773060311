/**
 *  store.ts
 *
 *
 */

import { createStore } from 'redux';
import rootReducer from './reducers';

declare global {
  interface Window { store: any; }
}

let appStore = createStore(rootReducer);
window.store = appStore;

export default appStore;
