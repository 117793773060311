import React, { Component } from 'react';
import styles from './EmotionMarker.module.scss';
import _ from 'lodash';

export interface Props {
  id: number;
  labels?: Emotion[];
  leftPos: string;
  bottomPos?: string;
  selectedLabel?: string;
  getLabels?: Function;
  onMouseEnter?: Function;
  onMouseLeave?: Function
  render?: Function;
}

export interface State {
  isHovering: boolean;
}

export interface Emotion {
  id?: string;
  index?: number;
  level?: number;
}

const getInitialState = (props: Props):State => {
  return {
    isHovering: false
  };
};

export class EmotionMarker extends Component<Props, State> {
  state = getInitialState(this.props);
  getLabels() {
    let labels: object[] = [],
        selectedLabel: string;
    if (typeof this.props.selectedLabel === 'undefined') {
      return [...labels];
    }
    selectedLabel = this.props.selectedLabel;
    labels = _.map(this.props.labels, (l: Emotion, i: number) => {
      let labelClasses = styles.EmotionMarkerLabel;
      if (selectedLabel === l.id) {
        labelClasses += ' ' + styles.EmotionMarkerLabelSelected;
      }
      return <span key={i} className={labelClasses}>{l.id}</span>;
    });
    return labels;
  }
  onMouseEnter() {
    this.setState({
      isHovering: true
    });
  }
  onMouseLeave() {
    this.setState({
      isHovering: false
    });
  }
  render() {
    let markerClasses: string = styles.EmotionMarker;
    if (this.state.isHovering === true) {
      markerClasses += ' ' + styles.EmotionMarkerHovering;
    }
    return (
      <div className={markerClasses}
        style={{
          left: this.props.leftPos,
          bottom: this.props.bottomPos
        }}>
        <div className={styles.EmotionMarkerButtonWrap}>
          <button
            className={styles.EmotionMarkerButton}
            onMouseEnter={(e) => { this.onMouseEnter() }}
            onMouseLeave={(e) => { this.onMouseLeave() }}></button>
          <span className={styles.EmotionMarkerLabels}>
            {this.getLabels()}
          </span>
        </div>
      </div>
    )
  }
}
