/**
 *  defaultLinks.ts
 *
 *
 */

interface LinkMarker {
  sys: {
    id: string
  },
  time: string
  linksCollection: {
    "items": CollectionLink[]
  }
}

interface CollectionLink {
  sys: {
    id: string;
  }
}

export const defaultLinks: LinkMarker[] = [
  {
    "sys": {
      "id": "480lf3RAwPfUX1jbeaeGSD"
    },
    "time": "1:00",
    "linksCollection": {
      "items": [
        {
          "sys": {
            "id": "niY9IlyvpvDXkTQ5Y6QQn"
          }
        }
      ]
    }
  }
];
