import React, { Component } from 'react';
import { connect } from "react-redux";
import styles from './StoryArcMarker.module.scss';
import { setCurrent } from '../redux/actions';
import { getCurrentMarkerID } from "../redux/selectors";

interface Props {
  id?: number;
  currentMarkerID?: number;
  label?: string;
  leftPos: string;
  topPos: string;
  setCurrent?: Function;
}

interface State {}

class StoryArcMarker extends Component<Props> {
  getMarkerClasses(): string {
    let classes: string = styles.StoryArcMarker;
    if (this.props.currentMarkerID) {
      if (this.props.currentMarkerID === this.props.id) {
        classes += ' ';
        classes += styles.StoryArcMarkerSelected;
      }
    }
    return classes;
  }
  handleClick() {
    if (this.props.setCurrent && this.props.id) {
      this.props.setCurrent(this.props.id);
    }
  }
  render() {
    let markerClasses: string = this.getMarkerClasses();
    return(
      <div 
        className={markerClasses}
        style={{
          left: this.props.leftPos,
          height: this.props.topPos
        }}>
        <button 
          className={styles.StoryArcMarkerLabel}
          onClick={() => { this.handleClick(); }}>
          {this.props.label}
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state: State) => {
  const currentMarkerID: number = getCurrentMarkerID(state);
  return { currentMarkerID };
};

export default connect(
  mapStateToProps,
  { setCurrent }
)(StoryArcMarker);