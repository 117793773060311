import React, { Component } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { hhmmssToSec } from '../utilities/time';
import styles from './Marker.module.scss';
import { setCurrent, addMarkerData } from '../redux/actions';
import { getCurrentFilters } from '../redux/selectors';
import { COLOR_SCALE } from '../constants';
import Thumb from './Thumb';

interface Props {
  addMarkerData?: Function;
  borderColor?: string;
  currentFilters?: string[];
  currentMarkerID?: number;
  emotionID?: string;
  key?: number;
  id?: number;
  image?: string;
  leftPos?: string;
  locationSysID?: string;
  markers?: any;
  note?: string;
  rangeMax: number;
  rangeMin?: number;
  seconds?: number;
  setCurrent?: Function;
  sysID?: string;
  themes?: Theme[];
  time: string;
}

interface EmotionItem {
  id: string;
}

interface ImageItem {
  url: string;
}

interface ColorObject {
  id?: string;
  index?: number;
  colorValue?: string;
}

interface Theme {
  id?: string
}

interface ThumbProps {
  id?: number;
  currentMarkerID?: number,
  image?: string;
  isActive?: boolean;
  marginLeft?: number;
  note?: string;
  opacity?: number;
  time?: string;
}

interface LocationItem {
  sys: {
    id: string;
  }
}

interface State {
  leftPos: string;
}

interface MarkerDataObject {
  location: {
    sys: {
      id: string;
    }
  }
}

const getInitialState = (props: Props):State => {
  return {
    leftPos: ''
  };
};

class Marker extends Component<Props, State, {}> {
  prototype: any;
  state = getInitialState(this.props);
  componentDidMount() {
    let leftPos = this.getLeftPos(this.props.time);
    this.setState({
      leftPos: leftPos
    });
    this.addData(leftPos);
  }
  addData(leftPos: string) {
    if (this.props.addMarkerData) {
      this.props.addMarkerData({
        id: this.props.id,
        leftPos: leftPos,
        emotionID: this.props.emotionID || null,
        sysID: this.props.sysID
      });
    }
  }
  getID():string {
    return 'marker_' + this.props.id;
  }
  getLeftPos(markerTime: string): string {
    return (hhmmssToSec(markerTime) / this.props.rangeMax * 100) + '%';
  }
  getColor(sourceArr: object[], thisID: string): string {
    let c: any = _.find(sourceArr, (c: ColorObject) => { 
      return thisID === c.id;
    });
    return c.colorValue;
  }
  getColors(): ColorObject[] {
    let groupColors: ColorObject[] = [],
        groups: string[] = [],
        uniqueGroups: string[] = [];
    if (!this.props.markers) {
      return [...groupColors];
    }
    for (let i = 0; i < this.props.markers.length; i++) {
      groups.push(this.props.markers[i].location.sys.id);
    }
    uniqueGroups = _.uniq(groups);
    _.each(uniqueGroups, (g: string, i) => {
      let colorObj = {
        index: i,
        id: g,
        colorValue: COLOR_SCALE[i]
      };
      groupColors.push(colorObj);
    });
    return [...groupColors];
  }
  getImage(): string {
    return (typeof this.props.image !== 'undefined') ? this.props.image : '';
  }
  getMarkerCount(): number {
    return (typeof this.props.markers !== 'undefined') ? this.props.markers.length : 0;
  }
  inFilters(): boolean {
    let currentFilters: string[] = this.props.currentFilters||[],
        isInFilters: boolean = false,
        theseThemes: Theme[] | undefined,
        thisFilter: string,
        thisTheme: Theme;
    if (this.props.themes) {
      theseThemes = this.props.themes;
    }
    if ((typeof theseThemes === 'undefined') || (typeof this.props.currentFilters === 'undefined')) {
      return isInFilters;
    }
    for (let i = 0; i < currentFilters.length; i++) {
      thisFilter = currentFilters[i];
      for (let j = 0; j < theseThemes.length; j++) {
        thisTheme = theseThemes[j];
        if (thisFilter === thisTheme.id) {
          isInFilters = true;
        }
      }
    }
    return isInFilters;
  }
  render() {
    var colors: ColorObject[] = this.getColors(),
        bgColor: string = '',
        borderLeftValue: string = '',
        markerClasses: string = styles.Marker,
        markerHeight: number|string = '',
        posTop: string = '',
        thumbProps: ThumbProps;
    if (this.props.locationSysID) {
      bgColor = this.getColor(colors, this.props.locationSysID);
      borderLeftValue = 'solid 2px ' + bgColor;
    }
    if ((typeof this.props.currentFilters !== 'undefined') &&
        (this.props.currentFilters.length > 0)) {
      markerHeight = 2;
      posTop = 'calc(2rem - 2px)';
    }
    if (this.inFilters()) {
      markerHeight = '';
      posTop = '';
    }
    if ((typeof this.props.currentFilters !== 'undefined') &&
        (this.props.currentMarkerID === this.props.id) &&
        (bgColor)) {
      markerHeight = '';
      posTop = '';
      markerClasses += ' ' + styles.MarkerActive;
      if ((this.props.currentFilters.length > 0) &&
          (this.inFilters() === false)) {
        borderLeftValue = 'dashed 1px ' + bgColor;
      }
    }
    thumbProps = {
      id: this.props.id,
      currentMarkerID: this.props.currentMarkerID,
      image: this.getImage(),
      isActive: false,
      note: this.props.note,
      time: this.props.time
    };
    return (
      <div 
        className={markerClasses}
        id={this.getID()}
        style={{
          left: this.state.leftPos
        }}>
        <button className={styles.MarkerButton}
          style={{
            borderLeft: borderLeftValue,
            height: markerHeight,
            top: posTop
          }}
          onClick={() => {
            if (this.props.setCurrent) {
              this.props.setCurrent(this.props.id)
            }
          }}>
          {this.props.id}
        </button>
        <Thumb {...thumbProps} />
      </div>
    )
  }
}

const mapStateToProps = (state: State) => {
  const currentFilters: string[] = getCurrentFilters(state);
  return { currentFilters };
};

export default connect(
  mapStateToProps,
  { setCurrent, addMarkerData }
)(Marker);
