import React, { Component } from 'react';
import { connect } from "react-redux";
import styles from './EmotionPlot.module.scss';
import EmotionCell from './EmotionCell';
import { EmotionMarker } from './EmotionMarker';
import { getMarkerData } from '../redux/selectors';
import _ from 'lodash';

interface Props {
  currentMarkers?: Marker[];
  dataEmotion?: Emotion[];
}

interface State {
  cellCols: number;
  cellRows: number;
  currentMarkers?: Marker[];
  markerData?: object[];
}

interface Marker {
  emotionID?: string;
  leftPos: string;
}

interface MarkerProps {
  bottomPos: string;
  labelArray: Emotion[];
}

interface Emotion {
  id?: string;
  index?: number;
  level?: number;
}

const getInitialState = (props: Props):State => {
  return {
    cellCols: 1,
    cellRows: 4,
    markerData: []
  };
};

class EmotionPlot extends Component<Props, State> {
  state = getInitialState(this.props);
  getCells() {
    let cells: object[] = [];
    _.forEach(Array(this.state.cellCols), () => {
      _.forEach(Array(this.state.cellRows), () => {
        cells.push(<EmotionCell key={cells.length} />);
      });
    });
    return [...cells];
  }
  getMarkerProps(emotion: Emotion, sourceArr: Emotion[]) {
    let bottomPos: string = '0rem',
        emotionArr: Emotion[] = [...sourceArr],
        emotionItem: Emotion = emotion,
        emotionIndex: number | null = null,
        labelArray: Emotion[] = [];
    if (typeof emotionItem.level !== 'undefined') {
      bottomPos = ((5 - emotionItem.level) * 2) + 'rem';
    }
    if ((typeof emotionArr !== 'undefined') &&
        (typeof emotionItem.id !== 'undefined')) {
      _.each(emotionArr, (em: Emotion, i: number) => {
        if ((typeof em.index !== 'undefined') &&
            (emotionItem.id === em.id)) {
          emotionIndex = em.index;
        }
      });
      if (typeof emotionIndex !== 'undefined') {
        labelArray = _.filter(emotionArr, (e: Emotion, i: number) => {
          return (e.index === emotionIndex);
        });
        labelArray.reverse();
      }
    }
    return {
      bottomPos: bottomPos,
      labelArray: labelArray
    };
  }
  getMarkers(): Marker[] {
    let allMarkers: Marker[] = [],
        emotionArr: Emotion[],
        markers: any[] = [];
    if (typeof this.props.dataEmotion !== 'undefined') {
      emotionArr = this.props.dataEmotion;
    }
    _.each(this.props.currentMarkers, (m: Marker, i: number) => {
      if (m.emotionID !== null) {
        allMarkers.push(m);
      }
    });
    _.each(allMarkers, (m: Marker, i: number) => {
      let emotion: Emotion,
          emotionID: string = '',
          markerProps: MarkerProps = {
            bottomPos: '',
            labelArray: []
          },
          props: any;
      _.each(emotionArr, (em: Emotion, j: number) => {
        if (m.emotionID === em.id) {
          emotion = em;
          markerProps = this.getMarkerProps(emotion, emotionArr);
        }
      });
      if (typeof m.emotionID !== 'undefined') {
        emotionID = m.emotionID;
      }
      props = {
        key: i,
        id: i,
        labels: markerProps.labelArray,
        leftPos: m.leftPos,
        bottomPos: markerProps.bottomPos,
        selectedLabel: emotionID
      };
      markers.push(<EmotionMarker {...props} />);
    });
    return [...markers];
  }
  render() {
    let cells = this.getCells(),
        markers = this.getMarkers();
    return (
      <div className={styles.EmotionPlot}>
        <div className={styles.EmotionCells}>
          {cells}
        </div>
        <div className={styles.EmotionMarkers}>
          {markers}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: State) => {
  const currentMarkers: Marker[] = getMarkerData(state);
  return { currentMarkers };
};

export default connect(
  mapStateToProps,
  null
)(EmotionPlot);
