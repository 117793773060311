import React, { Component } from 'react';
import { hhmmssToSec } from '../utilities/time';
import _ from 'lodash';
import styles from './StoryArc.module.scss';
import StoryArcMarker from './StoryArcMarker';

interface Props {
  markers?: any;
  rangeMax?: number;
}

interface ArcPoint {
  eightPointArcPoint: {
    label: string;
  }
  time: string;
}

class StoryArc extends Component<Props> {
  getLeftPos(markerTime: string): string {
    if (!this.props.rangeMax) {
      return '';
    } else {
      return (hhmmssToSec(markerTime) / this.props.rangeMax  * 100) + '%';
    }
  }
  getTopPos(index: number): string {
    return (index * (2)) + 5 + 'rem';
  }
  render() {
    var arcPoints: any = [],
        arcPointIndex: number = 0;
    _.forEach(this.props.markers, (p: ArcPoint, i: number) => {
      if (p.eightPointArcPoint !== null) {
        let label: string = p.eightPointArcPoint.label;
        arcPoints.push(<StoryArcMarker
          key={i}
          id={i}
          label={label}
          leftPos={this.getLeftPos(p.time)}
          topPos={this.getTopPos(arcPointIndex++)} />);
      }
    });
    return (
      <div className={styles.StoryArc}>
        {arcPoints}
      </div>
    )
  }
}
export default StoryArc;
