import React, { Component } from 'react';
import { connect } from "react-redux";
import styles from './Thumb.module.scss';
import cx from 'classnames';
import { setCurrent } from '../redux/actions';

export interface Props {
  id?: number;
  currentMarkerID?: number;
  image?: string;
  note?: string;
  setCurrent: Function;
  time?: string;
}

export interface RefObj {
  current?: HTMLElement;
}

class Thumb extends Component<Props> {
  private wrapRef = React.createRef<HTMLDivElement>();
  close() {
    this.props.setCurrent(-1);
  }
  getClassNames(baseStyle: string, activeStyle: string): string {
    let classArray: string[] = [baseStyle];
    if (this.isCurrent()) classArray.push(activeStyle);
    return classArray.join(' ');
  }
  getID(): string {
    return 'thumb_' + this.props.id;
  }
  getMarginLeft(): number {
    let refObj: React.RefObject<HTMLDivElement> = this.wrapRef,
        left: number = 0,
        rightEdge: number,
        thisWidth: number;
    if (this.isCurrent() && refObj.current) {
      rightEdge = refObj.current.getBoundingClientRect().left;
      rightEdge += thisWidth = refObj.current.getBoundingClientRect().width;
      rightEdge += 3;
      if (rightEdge > window.innerWidth) {
        left = -(thisWidth);
      }
    }
    return left;
  }
  getOpacity(): number {
    return this.isCurrent() ? 1 : 0;
  }
  isCurrent(): boolean {
    return (this.props.id === this.props.currentMarkerID);
  }
  render() {
    let note: string = this.props.note ?? ' ',
        time: string = this.props.time ?? ' ',
        marginLeft: number = this.getMarginLeft(),
        opacity: number = this.getOpacity();
    return (
      <div 
        ref={this.wrapRef as React.RefObject<HTMLDivElement>}
        id={this.getID()}
        className={cx(this.getClassNames(styles.ThumbWrap, styles.ThumbWrapActive))}
        style={{
          marginLeft: marginLeft,
          opacity: opacity
        }}>
        <figure
          className={styles.Thumb}>
          <img 
            className={styles.ThumbImage}
            src={this.props.image} 
            alt={note} />
          <figcaption 
            className={styles.ThumbFigcaption}>
            <span className={styles.FigcaptionTime}>{time}</span>
            <span className={styles.FigcaptionNote}>{note}</span>
          </figcaption>
        </figure>
        <button 
          className={styles.CloseButton}
          onClick={(e: React.MouseEvent<Element, MouseEvent>) => { this.close(); }}>
          <span className={styles.CloseButtonIcon}>×</span>
          <span className={styles.CloseButtonLabel}>Close</span>
        </button>
      </div>
    )
  }
}

export default connect(
  null,
  { setCurrent }
)(Thumb);
