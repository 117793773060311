import React, { Component } from 'react';
import { connect } from "react-redux";
import $ from 'jquery';
import axios from 'axios';
import { hhmmssToSec, getRangeMaxSec } from './utilities/time';
import { getCurrentMarkerID } from "./redux/selectors";
import { setCurrent, setExpandedLink } from './redux/actions';
import Timeline from './timeline/Timeline';
import styles from './App.module.scss';
import { queryAll } from './graphql/all';

require('dotenv').config();

interface State {
  dataEmotion: object[];
  dataTheme: object[];
  imageIndex: number;
  markers: Marker[];
  markerCount: number;
  rangeMin: number;
  rangeMax: number;
}

interface Props {
  currentMarkerID: number;
  setExpandedLink: Function;
  setCurrent: Function;
}

interface ImagesCollection {
  items: ImageCollectionItem[];
}

interface Marker {
  act: string;
  imagesCollection: ImagesCollection;
  note: string;
  time: string;
}

interface ImageCollectionItem {
  url: string;
}

interface SelectedItem {
  selectedImage?: string;
  selectedImageNote?: string;
}

const getInitialState = (props: Props):State => {
  return {
    dataEmotion: [],
    dataTheme: [],
    imageIndex: -1,
    markers: [],
    markerCount: 0,
    rangeMin: 0,
    rangeMax: 0
  };
}

class App extends Component<Props, State> {
  state = getInitialState(this.props);
  handleKey(e: KeyboardEvent) {
    e = e || window.event;
    if (e.keyCode === 37) { // left arrow
      this.focusPrevMarker();
    } else if (e.keyCode === 39) { // right arrow
      this.focusNextMarker();
    } else if (e.keyCode === 38) { // up arrow
      this.focusPrevMarker();
    } else if (e.keyCode === 40) { // down arrow
      this.focusNextMarker();
    }
  }
  focusNextMarker() {
    let currentID: number = -1,
        lastID: number = (this.state.markers.length - 1);
    if (typeof this.props.currentMarkerID !== 'undefined') {
      currentID = this.props.currentMarkerID;
    }
    if (typeof this.props.setExpandedLink !== 'undefined') {
      this.props.setExpandedLink(null);
    }
    if (typeof this.props.setCurrent === 'undefined') {
      return;
    }
    if (currentID === -1) {
      this.props.setCurrent(0);
    } else if (currentID >= lastID) {
      this.props.setCurrent(0);
    } else {
      this.props.setCurrent(currentID + 1);
    }
  }
  focusPrevMarker() {
    let currentID: number = -1,
        lastID: number = (this.state.markers.length - 1);
    if (typeof this.props.currentMarkerID !== 'undefined') {
      currentID = this.props.currentMarkerID;
    }
    if (typeof this.props.setExpandedLink !== 'undefined') {
      this.props.setExpandedLink(null);
    }
    if (typeof this.props.setCurrent === 'undefined') {
      return;
    }
    if (currentID === -1) {
      this.props.setCurrent(lastID);
    } else if (currentID <= 0) {
      this.props.setCurrent(lastID);
    } else {
      this.props.setCurrent(currentID - 1);
    }
  }
  getImagePath(imageIndex: number): string {
    let url: string = '',
        marker: Marker;
    if (typeof this.state.markers !== 'undefined') {
      marker = this.state.markers[imageIndex - 1];
      url = marker.imagesCollection.items[0].url;
    }
    return url;
  }
  loadLocalData() {
    axios
      .get('./data/all.json')
      .then((d: any) => {
        this.prepareData('local', d.data);
      })
      .catch((error: string) => {
        console.log(error);
      });
  }
  loadContentfulData() {
    let { REACT_APP_CTFL_ACCESS_TOKEN, REACT_APP_CTFL_SPACE_ID } = process.env,
        queryURI: string,
        query: string = queryAll;
    queryURI = `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_CTFL_SPACE_ID}`;
    fetch(queryURI,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${REACT_APP_CTFL_ACCESS_TOKEN}`
        },
        body: JSON.stringify({ query })
      })
      .then(response => response.json())
      .then((d: any) => this.prepareData('contentful', d.data))
      .catch((error) => {
        console.log(error);
      });
  }
  componentDidMount() {
    this.props.setCurrent(-1);
    // this.loadContentfulData();
    this.loadLocalData();
    document.onkeydown = (e: KeyboardEvent) => { this.handleKey(e); };
    $('html').on('click', (e: JQuery.Event) => {
      this.props.setExpandedLink('');
    });
  }
  componentWillUnmount() {
    document.onkeydown = function () {};
  }
  prepareData(type: string, d: any) {
    let data: any = d.data,
        markers: Marker[] = [],
        dataEmotion: object[] = [],
        dataTheme: object[] = [];
    for (let key in data.markerCollection.items) {
      markers.push(
        data.markerCollection.items[key]
      );
    }
    for (let key in data.emotionCollection.items) {
      dataEmotion.push(
        data.emotionCollection.items[key]
      );
    }
    for (let key in data.themeCollection.items) {
      dataTheme.push(
        data.themeCollection.items[key]
      );
    }
    markers = markers.sort(
      (a: any, b: any) => (hhmmssToSec(a.time) > hhmmssToSec(b.time)) ? 1 : -1
    );
    dataTheme = dataTheme.sort(
      (a: any, b: any) => (a.order > b.order) ? 1 : -1
    );
    this.setState({
      dataEmotion: [...dataEmotion],
      dataTheme: [...dataTheme],
      imageIndex: Math.ceil(Math.random() * markers.length),
      markers: [...markers],
      rangeMin: 0,
      rangeMax: getRangeMaxSec(markers),
      markerCount: markers.length
    });
    this.props.setCurrent(-1);
  }
  setImagePathString(markers: object[]): string {
    let imagePath: string = '';
    if (markers.length > 0) {
      imagePath += 'url(';
      imagePath += this.getImagePath(this.state.imageIndex);
      imagePath += ')';
    }
    return imagePath;
  }
  render() {
    let imagePath: string,
        markers: Marker[] = this.state.markers,
        props: any,
        selectedImage: string = '',
        selectedImageNote: string = '';
    if (typeof this.props.currentMarkerID === 'undefined') {
      return;
    }
    if ((this.props.currentMarkerID > -1) &&
        (markers.length > 0)) {
      selectedImage = markers[this.props.currentMarkerID].imagesCollection.items[0].url;
      selectedImageNote = markers[this.props.currentMarkerID].note;
    }
    imagePath = this.setImagePathString(markers);
    props = Object.assign({ selectedImage, selectedImageNote }, this.state);
    return (
      <div className={styles.App}>
        <div 
          className={styles.AppBackground}
          style={{
            backgroundImage: imagePath
          }}></div>
        <Timeline {...props} />
      </div>
    )
  }
}

const mapStateToProps = (state: State) => {
  const currentMarkerID: number = getCurrentMarkerID(state);
  return { currentMarkerID };
};

export default connect(
  mapStateToProps,
  { setCurrent, setExpandedLink}
)(App);
