/**
 *  links.js
 *
 *
 */

export const queryLinks = `{
  markerLinks: markerCollection {
    items {
      sys {
        id
      }
      time
      linksCollection {
        items {
          sys {
            id
          }
        }
      }
    }
  }
}`;