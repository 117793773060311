/**
 *  constants.js
 *
 *
 */

export const VIEW_OPTIONS: object[] = [
  {
    label: 'Locations',
    id: 'LOCATIONS'
  }
];

export const COLOR_SCALE: string[] = [
  "#993788",
  "#77b750",
  "#6f71d9",
  "#bfaa33",
  "#513688",
  "#64c77a",
  "#ca427f",
  "#45bc8d",
  "#d5474f",
  "#36dee6",
  "#802417",
  "#5e87d3",
  "#ce6431",
  "#c17cd3",
  "#488134",
  "#db7bb8",
  "#bdb85d",
  "#852a59",
  "#7b7928",
  "#e06f8a",
  "#d79754",
  "#9f2c47",
  "#975c22",
  "#cb5d60",
  "#ce7058"
];