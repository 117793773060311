import React, { Component } from 'react';
import { connect } from "react-redux";
import styles from './ThemeFilterButton.module.scss';
import { addFilter, removeFilter } from '../redux/actions';
import { getCurrentFilters } from '../redux/selectors';
import _ from 'lodash';

interface Props {
  addFilter?: Function;
  currentFilters?: string[];
  filterID?: string;
  label?: string;
  removeFilter?: Function;
}

interface State {}

class ThemeFilterButton extends Component<Props, {}> {
  isSelected: boolean = false;
  addFilter() {
    if (this.props.addFilter) this.props.addFilter(this.props.filterID);
  }
  removeFilter() {
    if (this.props.removeFilter) this.props.removeFilter(this.props.filterID);
  }
  toggleFilter() {
    if (this.isSelected === true) {
      this.removeFilter();
    } else {
      this.addFilter();
    }
  }
  render() {
    let buttonClasses: string = styles.ThemeFilterButton,
        filterID: string = this.props.filterID ?? '',
        isSelected: boolean | null = null;
    isSelected = (typeof _.find(this.props.currentFilters, (f: string, i: number) => {
      return (filterID === f);
    }) !== 'undefined');
    if (isSelected === true) {
      this.isSelected = true;
      buttonClasses += ' ' + styles.ThemeFilterButtonSelected;
    } else {
      this.isSelected = false;
    }
    return(
      <button
        className={buttonClasses}
        onClick={() => { this.toggleFilter(); }}>
        <span className={styles.Label}>{this.props.label}</span>
      </button>
    )
  }
}

const mapStateToProps = (state: State) => {
  const currentFilters: string[] = getCurrentFilters(state);
  return { currentFilters };
};

export default connect(
  mapStateToProps,
  { addFilter, removeFilter }
)(ThemeFilterButton);