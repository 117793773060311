import React, { Component } from 'react';
import styles from './EmotionCell.module.scss';

class EmotionCell extends Component {
  render() {
    return (
      <div className={styles.EmotionCell} />
    )
  }
}
export default EmotionCell;
