/**
 *  markers.js
 *
 *
 */

import {
  SET_CURRENT,
  ADD_MARKER_DATA,
  ActionTypes
} from '../actionTypes';
import _ from 'lodash';

interface Marker {
  id?: number;
  leftPos?: string;
  emotionID?: string;
  sysID?: string;
}

interface State {
  currentMarkerID?: number;
  markerData?: Marker[];
}

const initialState: State = {
  currentMarkerID: -1,
  markerData: []
};

export default function(state: State = initialState, action: ActionTypes) {
  switch (action.type) {
    case SET_CURRENT: {
      const actionType: any = action.payload;
      return {
        ...state,
        currentMarkerID: actionType.currentMarkerID
      };
    }
    case ADD_MARKER_DATA: {
      const actionType: any = action.payload;
      let found: any,
          markerData: Marker[] = [];
      if (typeof state.markerData !== 'undefined') {
        markerData = [...state.markerData];
        found = _.find(markerData, (m: Marker, i: number) => {
          return (m.id === actionType.marker.id);
        });
        if (found) {
          markerData =  state.markerData 
        } else {
          markerData.push(actionType.marker);
        }
      }
      return {
        ...state,
        markerData: [...markerData]
      };
    }
    default:
      return state;
  }
}
