/**
 *  actions.js
 *
 *
 */

import {
  SET_CURRENT,
  SET_EXPANDED_LINK,
  ADD_FILTER,
  ADD_MARKER_DATA,
  REMOVE_FILTER
} from './actionTypes';

export const setCurrent = (currentMarkerID: number) => ({ 
  type: SET_CURRENT,
  payload: { currentMarkerID }
});

export const setExpandedLink = (expandedLinkID: number) => ({ 
  type: SET_EXPANDED_LINK,
  payload: { expandedLinkID }
});

export const addFilter = (filterID: string) => ({ 
  type: ADD_FILTER,
  payload: filterID
});

export const addMarkerData = (marker: any) => ({ 
  type: ADD_MARKER_DATA,
  payload: { marker }
});

export const removeFilter = (filterID: string) => ({ 
  type: REMOVE_FILTER,
  payload: filterID
});
