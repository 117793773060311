import React, { Component } from 'react';
import { hhmmssToSec } from '../utilities/time';
import _ from 'lodash';
import styles from './Grid.module.scss';
import { GridLine } from './GridLine';

interface Props {
  markers?: Marker[];
  rangeMax?: number;
}

interface Marker {
  act?: number;
  time?: string;
}

class Grid extends Component<Props> {
  getLeftPos(markerTime: string): string {
    if (typeof this.props.rangeMax === 'undefined') return '';
    return (hhmmssToSec(markerTime) / this.props.rangeMax * 100) + '%';
  }
  getGridLines() {
    let currentAct: number = 0,
        gridLines: any = [];
    if ((typeof this.props.markers === 'undefined') ||
        (this.props.markers.length < 1)) {
      return [...gridLines];
    }
    _.forEach(this.props.markers, (m: Marker, i: number) => {
      if (m && m.act && m.time) {
        if (m.act !== currentAct) {
          currentAct = m.act;
          gridLines.push(<GridLine 
            key={i}
            act={m.act}
            label={`Act ${m.act}`}
            leftPos={this.getLeftPos(m.time)} 
            />);
        }
      }
    });
    return [...gridLines];
  }
  render() {
    let gridLines: GridLine[] = this.getGridLines();
    return (
      <div className={styles.Grid}>
        {gridLines}
      </div>
    )
  }
}

export default Grid;
