import React, { Component } from 'react';
import { hhmmssToSec } from '../utilities/time';
import styles from './Markers.module.scss';
import Marker from './Marker';

interface Props {
  currentMarkerID?: number;
  markers: Array<typeof Marker>;
  rangeMax?: number;
}

interface MarkerProps {
  borderColors?: string[];
  currentMarkerID: number;
  emotionID: string;
  key: number;
  id: number;
  image: string;
  locationSysID: string;
  borderColor: string;
  markers: Array<typeof Marker>;
  markerCount: number;
  note: string;
  rangeMax: number;
  seconds: number;
  sysID: string;
  themes: Theme[];
  time: string;
}

export interface Theme {
  id?: string
}

class Markers extends Component<Props, object> {
  getMarkers() {
    let borderColor: string,
        borderColors: string[] = [ '#333', '#fff'],
        currentAct: number | undefined,
        emotionID: string,
        id:number = -1,
        markers: any = [];
    if (typeof this.props.markers !== 'undefined') {
      markers = this.props.markers.map((m: any) => {
        borderColor = borderColors[0];
        emotionID = '';
        if (m.act !== currentAct) {
          borderColor = borderColors[1];
          currentAct = m.act;
        }
        if (m.emotionCollection && m.emotionCollection.items.length > 0) {
          emotionID = m.emotionCollection.items[0].id;
        }
        id++;
        return (<Marker
          currentMarkerID={this.props.currentMarkerID}
          emotionID={emotionID}
          key={id}
          id={id}
          image={(m.imagesCollection) ? m.imagesCollection.items[0].url : ''}
          locationSysID={(m.location) ? m.location.sys.id : ''}
          borderColor={borderColor}
          markers={this.props.markers}
          note={m.note ?? ''}
          rangeMax={this.props.rangeMax ?? 0}
          seconds={(m.time) ? hhmmssToSec(m.time) : 0}
          sysID={(m.sys) ? m.sys.id : ''}
          themes={(m.themeCollection) ? m.themeCollection.items : []}
          time={m.time ?? ''} />);
      });
    }
    return markers;
  }
  render() {
    let markerNodes = new Array<typeof Marker>(),
        markersClasses: string = styles.Markers;
    if ((this.props.markers) &&
        (this.props.markers.length > 0)) {
      markerNodes = this.getMarkers();
    }
    if (this.props.currentMarkerID !== -1) {
      markersClasses += ' ';
      markersClasses += styles.MarkersActive;
    }
    return (
      <div
        id="markers"
        className={markersClasses}>
        {markerNodes}
      </div>
    )
  }
}

export default Markers;