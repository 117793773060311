/**
 *  links.js
 *
 *
 */

import {
  SET_EXPANDED_LINK,
  ActionTypes
} from '../actionTypes';

interface State {
  expandedLinkID: number | null;
}

const initialState: State = {
  expandedLinkID: null
};

export default function(state: State = initialState, action: ActionTypes) {
  switch (action.type) {
    case SET_EXPANDED_LINK: {
      const actionType: any = action.payload;
      return {
        ...state,
        expandedLinkID: actionType.expandedLinkID
      };
    }
    default:
      return state;
  }
}
