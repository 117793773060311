/**
 *  time.js
 *
 *
 */

interface TimeObject {
  time: string;
}

/**
 *  Returns time in seconds
 *
 *  @param {string}    a    Time in hh:mm:ss format
 *
 */
export function hhmmssToSec(a: string): number {
  let seconds:number = 0,
      arrayTime: string[] = a.split(':');

  if (arrayTime.length > 2) {
    // Convert hours to seconds
    seconds = Number(arrayTime[0]) * 60 * 60;
  }

  // Convert minutes to seconds
  seconds += Number(arrayTime[arrayTime.length - 2]) * 60;

  // Seconds
  seconds += Number(arrayTime[arrayTime.length - 1]);
  return seconds;
}

/**
 *  Returns smallest time in seconds
 *
 *  @param {array}    t    Array of objects with time property in hh:mm:ss
 *
 */
export function getRangeMinSec(t: TimeObject[]): number {
  return Math.min(
    ...t.map(
      (timeObject: TimeObject) => hhmmssToSec(timeObject.time)
    )
  );
}

/**
 *  Returns smallest time in seconds
 *
 *  @param {array}    t    Array of objects with time property in hh:mm:ss
 *
 */
export function getRangeMaxSec(t: TimeObject[]): number {
  return Math.max(
    ...t.map(
      (timeObject: TimeObject) => hhmmssToSec(timeObject.time)
    )
  );
}
