/**
 *  defaultMarkerData.ts
 *
 *
 */

interface Marker {
  imagesCollection: {
    items: Image[]
  }
  note: string
  sys: {
    id: string
  }
  time: string
}

interface Image {
  sys: {
    id: string;
  }
  url: string;
}

export const defaultMarkerData: Marker[] = [
  {
    "sys": {
      "id": "480lf3RAwPfUX1jbeaeGSD"
    },
    "time": "1:00",
    "note": "Start",
    "imagesCollection": {
      "items": [
        {
          "sys": {
            "id": "7tauc58KPsnLwmhDdyk7ct"
          },
          "url": "https://images.ctfassets.net/wpxqxyw7rbn3/7tauc58KPsnLwmhDdyk7ct/c43d7f16de72e6e892a2daccfe47dd2a/0162.jpg"
        }
      ]
    }
  }
];
