/**
 *  selectors.ts
 *
 *
 */

interface Marker {
  id: number;
  leftPos: string;
  emotionID: string;
  sysID: string;
}

interface Store {
  currentMarkerID: number;
  markers: {
    markerData: Marker[];
    currentMarkerID: number;
  }
  filters: {
    currentFilters: string[];
  }
  links: {
    expandedLinkID: number;
  }
}

export const getCurrentMarkerID: Function = (store: Store) => store.markers.currentMarkerID;
export const getMarkerData: Function = (store: Store) => store.markers.markerData;
export const getCurrentFilters: Function = (store: Store) => store.filters.currentFilters;
export const getExpandedLinkID: Function = (store: Store) => store.links.expandedLinkID;
