import React, { Component } from 'react';
import { connect } from "react-redux";
import styles from './Link.module.scss';
import cx from 'classnames';
import { setExpandedLink } from '../redux/actions';
import { getExpandedLinkID } from "../redux/selectors";

export interface Props {
  cardRef?: HTMLButtonElement;
  wrapperRef?: HTMLDivElement;
  expandedLinkID: number;
  id: number;
  img: string;
  isLast: boolean;
  leftPos: string;
  note: string;
  parent?: HTMLDivElement;
  setExpandedLink: Function;
  time: string;
  topPos: string;
}

export interface State {
  buttonWidth: number;
}

const getInitialState = (props: Props):State => {
  return {
    buttonWidth: 10
  };
};

class Link extends Component<Props, State> {
  cardRef = React.createRef<HTMLButtonElement>();
  wrapperRef = React.createRef<HTMLDivElement>();
  state = getInitialState(this.props);
  expanded: boolean = false;
  getCxNames(typeCxName: string, baseStyle: string): string {
    var cxNames: string[] = [baseStyle];
    cxNames.push(typeCxName);
    return cxNames.join(' ');
  }
  resetPosition() {
    if (this.cardRef && this.cardRef.current) {
      this.cardRef.current.style.marginLeft = '';
    }
  }
  getLeftPos(): number {
    let extent: number,
        left: number,
        newLeft: number = -4,
        width: number,
        parentWidth: number;
    if (this.props.parent && 
        this.cardRef.current && 
        this.wrapperRef.current) {
      left = this.wrapperRef.current.getBoundingClientRect().left;
      width = 200;
      parentWidth = this.props.parent.getBoundingClientRect().width;
      extent = left + width;
      if ((extent >= parentWidth) &&
          (this.expanded === true)) {
        newLeft = -((width - 1) - (this.state.buttonWidth/2))
      }
    }
    return newLeft;
  }
  toggleCard(e: React.MouseEvent<Element, MouseEvent>) {
    if (this.props.id === this.props.expandedLinkID) {
      this.props.setExpandedLink('');
      this.expanded = false;
    } else {
      this.props.setExpandedLink(this.props.id);
      this.expanded = true;
    }
    e.stopPropagation();
  }
  render() {
    let linkClassNames: string = cx(this.getCxNames('link', styles.Link));
    if (this.props.id === this.props.expandedLinkID) {
      linkClassNames += ' ' + styles.LinkExpanded;
      this.expanded = true;
    } else {
      this.expanded = false;
    }
    return(
      <div
        ref={this.wrapperRef}
        className={linkClassNames}
        style={{
          left: this.props.leftPos
        }}>
        <span className={styles.LinkPointer}></span>
        <button
          className={cx(this.getCxNames('link-card', styles.LinkCard))}
          ref={this.cardRef}
          onClick={(e: React.MouseEvent<Element, MouseEvent>) => { this.toggleCard(e) }}
          style={{
            marginLeft: this.getLeftPos()
          }}>
          <span className={styles.LinkTime}>{this.props.time}</span>
          <span className={styles.LinkNote}>{this.props.note}</span>
          <div className={styles.CloseButton}>
            <span className={styles.CloseButtonIcon}>×</span>
            <span className={styles.CloseButtonLabel}>Close</span>
          </div>
          <img
            src={this.props.img}
            className={styles.LinkCardImage}
            alt={this.props.note} />
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state: State) => {
  const expandedLinkID: number = getExpandedLinkID(state);
  return { expandedLinkID };
};

export default connect(
  mapStateToProps,
  { setExpandedLink }
)(Link);
