import React, { Component } from 'react';
import styles from './GridLine.module.scss';

export interface Props {
  act?: number;
  label?: string;
  leftPos?: string;
}

export class GridLine extends Component<Props> {
  render() {
    return(
      <div 
        className={styles.GridLine}
        style={{
          left: this.props.leftPos
        }}>
        <span className={styles.GridLineLabel}>
          {this.props.label}
        </span>
      </div>
    )
  }
}