import React, { Component, ReactNode } from 'react';
import styles from './GroupMarks.module.scss';
import { hhmmssToSec } from '../utilities/time';

export interface Props {
  currentMarkerID: number;
  dataEmotion?: object[];
  dataTheme?: object[];
  markers?: Marker[];
  marks?: ReactNode[];
  rangeMax: number;
  rangeMin: number;
  selectedImage?: string;
  selectedImageNote?: string;
}

export interface Marker {
  act: number;
  time: string;
  location: {
    label: string;
  };
}

export class GroupMarks extends Component<Props> {
  getGroupMarks(): ReactNode[] {
    let marks: ReactNode[] = [];
    if ((this.props) &&
        (this.props.markers)) {
      marks = this.props.markers.map((m: Marker, i: number) => {
        return <div
            key={i}
            style={{ left: this.getLeftPos(m.time) }}
            className={styles.GroupMark}><span className={styles.GroupMarkLabel}>{m.location.label}</span></div>;
      });
    }
    return marks;
  }
  getLeftPos(markerTime: string): string {
    if (typeof this.props.rangeMax === 'undefined') {
      return '';
    }
    return (hhmmssToSec(markerTime) / this.props.rangeMax * 100) + '%';
  }
  render() {
    let marks: ReactNode[] = [];
    if ((this.props.markers) &&
        (this.props.markers.length > 0)) {
      marks = [...this.getGroupMarks()];
    }
    return (
      <div className={styles.GroupMarks}>
        {marks}
      </div>
    );
  }
}
