/**
 *  actionType.js
 *
 *
 */

export const SET_CURRENT: string       = 'SET_CURRENT';
export const SET_EXPANDED_LINK: string = 'SET_EXPANDED_LINK';
export const ADD_FILTER: string        = 'ADD_FILTER';
export const ADD_MARKER_DATA: string   = 'ADD_MARKER_DATA';
export const REMOVE_FILTER: string     = 'REMOVE_FILTER';

interface SetCurrentAction {
  type: typeof SET_CURRENT;
  payload: {
    currentMarkerID: number
  };
}

interface SetExpandedLinkAction {
  type: typeof SET_EXPANDED_LINK
  payload: {
    expandedLinkID: number
  }
}

interface AddFilterAction {
  type: typeof ADD_FILTER
  payload: {
    filterID: string
  }
}

interface AddMarkerDataAction {
  type: typeof ADD_MARKER_DATA
  payload: {
    marker: {
      id: number
      leftPos: string
      emotionID: string
      sysID: string
    }
  }
}

interface RemoveFilterAction {
  type: typeof REMOVE_FILTER;
  payload: {
    filterID: string;
  }
}

export type ActionTypes = SetCurrentAction | SetExpandedLinkAction | AddFilterAction | AddMarkerDataAction | RemoveFilterAction

