import React, { Component } from 'react';
import { connect } from "react-redux";
import styles from './Timeline.module.scss';
import EmotionPlot from './EmotionPlot';
import Grid from './Grid';
import { GroupMarks } from './GroupMarks';
import Help from './Help';
import Links from './Links';
import Markers from './Markers';
import StoryArc from './StoryArc';
import ThemeFilter from './ThemeFilter';
import { getCurrentMarkerID } from "../redux/selectors";

interface Props {
  currentMarkerID: number;
  dataEmotion?: object[];
  dataTheme?: object[];
  markers: any;
  rangeMax: number;
  rangeMin: number;
  selectedImage?: string;
  selectedImageNote?: string;
}

interface State {
  currentMarkerID: number;
}

class Timeline extends Component<Props> {
  render() {
    return (
      <div className={styles.Timeline}>
        <EmotionPlot {...this.props} />
        <Grid {...this.props} />
        <StoryArc {...this.props} />
        <Markers {...this.props} />
        <GroupMarks {...this.props} />
        <Links {...this.props} />
        <ThemeFilter {...this.props} />
        <Help />
      </div>
    )
  }
}

const mapStateToProps = (state: State) => {
  const currentMarkerID = getCurrentMarkerID(state);
  return { currentMarkerID };
};

export default connect(
  mapStateToProps,
  null
)(Timeline);
