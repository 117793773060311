import React, { Component } from 'react';
import styles from './Help.module.scss';

interface Props {}

interface State {
  contentVisible: boolean;
}

const getInitialState = (props: Props):State => {
  return {
    contentVisible: false
  };
};

class Help extends Component<Props, State> {
  state = getInitialState(this.props);
  toggle() {
    if (this.state.contentVisible === false) {
      this.setState({ contentVisible: true });
    } else {
      this.setState({ contentVisible: false });
    }
  }
  render() {
    let contentClasses: string = styles.HelpContent,
        toggleIcon: string = '?',
        toggleIconClass: string = styles.HelpToggleIcon;
    if (this.state.contentVisible === true) {
      contentClasses += ' ';
      contentClasses += styles.HelpContentVisible;
      toggleIcon = '×';
      toggleIconClass += ' ';
      toggleIconClass += styles.HelpToggleIconClose;
    }
    return(
      <div 
        className={styles.Help}>
        <button 
          className={styles.HelpToggle}
          onClick={(e) => { this.toggle(); }}>
          <span className={toggleIconClass}>{toggleIcon}</span>
          <span className={styles.HelpToggleLabel}>Help</span>
        </button>
        <div className={contentClasses}>
          <div className={styles.HelpContentInset}>
            <h2 className={styles.HelpContentTitle}>
              Help
            </h2>
            <div className={styles.HelpContentBody}>
              <p>Use the left and right arrow keys on your keyboard to move forward and back on the timeline.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Help;