/**
 *  index.js
 *
 *
 */

import { combineReducers } from 'redux';
import filters from './filters';
import links from './links';
import markers from './markers';

export default combineReducers({ filters, markers, links });
