/**
 *  filters.js
 *
 *
 */

import {
  ADD_FILTER,
  REMOVE_FILTER,
  ActionTypes
} from '../actionTypes';
import _ from 'lodash';

interface State {
  currentFilters: string[]
}

const initialState: State = {
  currentFilters: []
};

export default function(state: State = initialState, action: ActionTypes) {
  switch (action.type) {
    case ADD_FILTER: {
      const filterID = action.payload;
      return {
        ...state,
        currentFilters: [...state.currentFilters, filterID]
      };
    }
    case REMOVE_FILTER: {
      const filterID = action.payload;
      let newFilters: string[] = [...state.currentFilters];
      _.remove(newFilters, (f: any, i) => {
        return (f === filterID);
      });
      return {
        ...state,
        currentFilters: [...newFilters]
      };
    }
    default:
      return state;
  }
}
