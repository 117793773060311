/**
 *  all.js
 *
 *
 */

export const queryAll = `{
  markerCollection {
    items {
      sys {
        id
      }
      time
      note
      act
      eightPointArcPoint {
        sys {
          id
        }
        label
        order
      }
      location {
        sys {
          id
        }
        label
      }
      emotionCollection (limit:1) {
        items {
          id
        }
      }
      themeCollection {
        items {
          label
          id
        }
      }
      imagesCollection(limit:1) {
        items {
          sys {
            id
          }
          url
        }
      }
    }
  }
  emotionCollection {
    items {
      sys {
        id
      }
      id
      index
      level
    }
  }
  themeCollection {
    items {
      sys {
        id
      }
      id
      label
      order
    }
  }
}`;
