import React, { Component } from 'react';
import { connect } from "react-redux";
import styles from './ThemeFilter.module.scss';
import { setCurrent } from '../redux/actions';
import ThemeFilterButton from './ThemeFilterButton';

interface Props {
  dataTheme?: Theme[];
  setCurrent?: Function;
}

interface Theme {
  id?: string;
  label?: string;
}

class ThemeFilter extends Component<Props> {
  resetCurrent() {
    if (this.props.setCurrent) this.props.setCurrent(-1);
  }
  render() {
    let themeFilterButtons: any[] = [];
    if (this.props.dataTheme) {
      themeFilterButtons = this.props.dataTheme.map((b: Theme, i) => {
        return <ThemeFilterButton
                  key={i}
                  filterID={b.id}
                  label={b.label} />;
      });
    }
    return (
      <div className={styles.ThemeFilter}>
        <h3 className={styles.ThemeFilterHeading}>
          Main themes:
        </h3> 
        <span className={styles.ThemeFilterButtons}>
          {themeFilterButtons}
        </span>
      </div>
    )
  }
}

export default connect(
  null,
  { setCurrent }
)(ThemeFilter);
